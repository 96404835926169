import Avatar from "@Atom/Avatar";
import Button from "@Atom/Button";
import Card from "@Atom/Card";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function ProfileDropdown() {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();

  return (
    <Card className={Styles.container}>
      <div className={Styles.top}>
        <Avatar iconName="user" size="38px" iconSize="24px" iconColor="#000" />
        <span>{user?.name}</span>
      </div>
      <div className={Styles.middle}>
        {user.isInstructor || user.role === "INTERNAL" ? (
          <Button
            onClick={() => {
              navigate(
                user.role === "INTERNAL"
                  ? "/admin"
                  : user?.isInstructor
                  ? "/instructor"
                  : "/student"
              );
            }}
            variant="top-nav"
            text={"Dashboard"}
          />
        ) : (
          <Button
            variant="top-nav"
            text={"Kelas Saya"}
            onClick={() => {
              navigate("/student");
            }}
          />
        )}
      </div>
      <div className={Styles.bottom}>
        {/* <Button variant="top-nav" text={"Profile"} /> */}
        <Button
          variant="top-nav"
          text={"Logout"}
          onClick={() => {
            sessionStorage?.clear();
            localStorage?.clear(navigate("/login"));
          }}
        />
      </div>
    </Card>
  );
}
