import Button from '@Atom/Button'
import DoodleGradientCard from '@Atom/DoodleGradientCard'
import PhotoField from '@Atom/PhotoField'
import { decryptStorageData } from '@Helpers/encryptDecrypt'
import Footer from '@Molecule/Footer'
import InputField from '@Molecule/InputField'
import Images from '@Theme/Images'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import FormErrorMessage from '@Atom/FormErrorMessage'
import useWindowSize from '@Hooks/useWindowSize'

export default function AdminCreateInstructorLayout() {
  const user = decryptStorageData('user', localStorage)
  const [error, setError] = useState('')

  const [form, setForm] = useState({
    img: null,
    name: '',
    phoneNumber: '',
    nik: '',
    npwp: '',
    ig: '',
    fb: '',
    x: '',
    biography: '',
    accountHolder: '',
    bankName: '',
    accountNumber: ''
  })

  const handleChangeForm = useCallback((name, newVal) => {
    setForm(prev => {
      return {
        ...prev,
        [name]: newVal
      }
    })
  }, [])

  const { width } = useWindowSize()

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  }, [error, setError]);

  const template = useMemo(() => {
    return [
      {
        title: 'Informasi Akun',
        component:
          <div className={Styles.informationSection}>
            <PhotoField
              value={form?.img}
              setValue={(newVal) => handleChangeForm('img', newVal)}
            />
            <div className={Styles.infoFields}>
              <InputField
                title={'Name'}
                required
                value={form?.name}
                setValue={(newVal) => handleChangeForm('name', newVal)}
                placeholder='Masukkan nama instruktur'
              />
              <InputField
                title={'No. Handphone'}
                required
                value={form?.phoneNumber}
                setValue={(newVal) => handleChangeForm('phoneNumber', newVal)}
                placeholder='Masukkan no hanphone instruktur'
                isPhoneNumber
              />
              <div className={Styles.grid}>
                <InputField
                  title={'NIK'}
                  value={form?.nik}
                  setValue={(newVal) => handleChangeForm('nik', newVal)}
                  placeholder='Masukkan NIK instruktur'
                />
                <InputField
                  title={'NPWP'}
                  value={form?.npwp}
                  setValue={(newVal) => handleChangeForm('npwp', newVal)}
                  placeholder='Masukkan NPWP instruktur'
                />
              </div>
              <div className={Styles.grid}>
                <InputField
                  title={'Instagram'}
                  value={form?.ig}
                  setValue={(newVal) => handleChangeForm('ig', newVal)}
                  placeholder='@john_doe'
                  startIcon='instagram'
                />
                <InputField
                  title={'Facebook'}
                  value={form?.fb}
                  setValue={(newVal) => handleChangeForm('fb', newVal)}
                  placeholder='@john_doe'
                  startIcon='facebook'
                />
                <InputField
                  title={'X'}
                  value={form?.x}
                  setValue={(newVal) => handleChangeForm('x', newVal)}
                  placeholder='@john_doe'
                  startIcon='x-twitter'
                />
              </div>
            </div>
          </div>
      },
      {
        title: 'Biografi Instruktur.',
        component:
          <InputField
            title={'Biografi'}
            value={form?.biography}
            setValue={(newVal) => handleChangeForm('biography', newVal)}
            placeholder='Tuliskan biografi instruktur disini'
            isTextArea
            required

          />
      },
      {
        title: 'Rekening Bank',
        component:
          <div className={Styles.bankAccountSection}>
            <InputField
              title={'Nama Pemilik Rekening'}
              value={form?.accountHolder}
              setValue={(newVal) => handleChangeForm('accountHolder', newVal)}
              placeholder='Masukkan nama pemilik rekening'
            />
            <div className={Styles.grid}>
              <InputField
                title={'Nama Bank'}
                value={form?.bankName}
                setValue={(newVal) => handleChangeForm('bankName', newVal)}
                placeholder='Masukkan nama bank'
              />
              <InputField
                title={'Nomor Rekening'}
                value={form?.accountNumber}
                setValue={(newVal) => handleChangeForm('accountNumber', newVal)}
                placeholder='Masukkan nomor rekening anda'
              />
            </div>
            <FormErrorMessage
              message={error}
            />
            <div className={Styles.submit}>
              <Button
                text={'Simpan'}
                disabled={!form?.name || !form?.phoneNumber}
              />
            </div>
          </div>
      }
    ]
  }, [error, form?.accountHolder, form?.accountNumber, form?.bankName, form?.biography, form?.fb, form?.ig, form?.img, form?.name, form?.nik, form?.npwp, form?.phoneNumber, form?.x, handleChangeForm])

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat Datang {<span>{user?.name}</span>}</h3>
            <span>Silahkan lakukan pengaturan terhadap akun kamu di halaman ini</span>
          </div>
          {
            width > 768
            &&
            <img className={Styles.img} src={Images.ID_CARD} alt='' />
          }
        </DoodleGradientCard>
        <div className={Styles.content}>
          {template?.map((t, tI) => (
            <div
              key={tI}
              className={Styles.each}
            >
              <span>{t?.title}</span>
              {t?.component}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}