import React, { useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import TransparentSearchbar from "@Atom/TransparentSearchbar";
import Icon from "@Atom/Icon";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Footer from "@Molecule/Footer";
import Button from "@Atom/Button";
import Modal from "@Atom/Modal";
import InputField from "@Molecule/InputField";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";
import Images from "@Theme/Images";
import {
  createMasterDataCertificate,
  deleteMasterDataCertificate,
  updateMasterDataCertificate,
} from "@Services/admin";
import { useNavigate } from "react-router-dom";
import { fileBaseUrl } from "@Config/index";
import LogoBlendQ from "@Assets/Images/blend-question.png";
import FileUploadSuccess from "@Molecule/FileUploadSuccessModal";
import ModalError from "@Molecule/ModalError";

export default function MasterDataCertificate({ isLoading, data }) {
  const user = decryptStorageData("user", localStorage);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [hovered, setHovered] = useState(false);
  const inputFileRef = useRef();
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [errorDelete, setErrorDelete] = useState([]);

  const handleChangeCertificate = (name, value) => {
    setSelectedCertificate((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitCertificate = async () => {
    try {
      if (selectedCertificate._id) {
        const res = await updateMasterDataCertificate(
          selectedCertificate._id,
          selectedCertificate
        );
        if (res.status) {
          setSelectedCertificate(null);
          setSuccessModal(true);
        }
      } else {
        const res = await createMasterDataCertificate(selectedCertificate);
        if (res.status) {
          setSelectedCertificate(null);
          setSuccessModal(true);
        }
      }
      navigate("", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCertificate = async (id) => {
    try {
      const res = await deleteMasterDataCertificate(id);
      if (res.status) {
        setModalConfirmDelete(false);
        navigate("", { replace: true });
      }
    } catch (error) {
      setErrorDelete(error.response.data.data);
    }
  };

  const dataToMap = useMemo(() => {
    if (data?.data) {
      return data?.data.filter((el) => el.name.includes(searchTerm));
    } else {
      return [];
    }
  }, [searchTerm, data]);

  if (isLoading) {
    return (
      <div className={Styles.container}>
        <div className={Styles.loadingContainer}>
          <Lottie animationData={loadingClass} loop={true} />
          <p>Memuat Kontent</p>
        </div>
      </div>
    );
  }

  console.log(selectedCertificate);
  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat datang {<span>{user?.name}</span>}</h3>
            <span>
              Berikut adalah daftar template sertifikat yang dapat kamu gunakan
              nanti di setiap kelas yang ada
            </span>
            <TransparentSearchbar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder="Cari"
            />
          </div>
          <button
            onClick={() =>
              setSelectedCertificate({
                name: "",
                file: null,
              })
            }
          >
            <Icon icon={"plus"} size={10} />
            <span>Tambah Sertifikat</span>
          </button>
        </DoodleGradientCard>
        {dataToMap.length ? (
          <div className={Styles.cardContainer}>
            {dataToMap.map((el, idx) => (
              <div className={Styles.certficateCard} key={idx}>
                <div className={Styles.cardHead}>
                  <p>{el.name}</p>
                  <div>
                    <Icon
                      icon={"edit-2"}
                      size={20}
                      color={"#C2C2C2"}
                      onClick={() => setSelectedCertificate(el)}
                    />
                    <Icon
                      icon={"trash1"}
                      size={20}
                      color={"#C2C2C2"}
                      onClick={() => setModalConfirmDelete(el)}
                    />
                  </div>
                </div>
                <div>
                  <img src={fileBaseUrl + el.file} loading="lazy" alt="" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={Styles.emptyHandleContainer}>
            <img src={Images.CERTIFICATE_EMPTY} alt="" />
            <p>Belum ada sertifikat ditambahkan ke daftar template.</p>
            <Button
              text={"Tambah Sertfikat"}
              startIcon={"add"}
              onClick={() =>
                setSelectedCertificate({
                  name: "",
                  file: null,
                })
              }
            />
          </div>
        )}
      </div>

      <Modal isOpen={selectedCertificate}>
        <div className={Styles.modalContainer}>
          <div className={Styles.modalHeader}>
            <p>Tambah Sertifikat</p>
          </div>
          <div className={Styles.modalBody}>
            <InputField
              title={"Nama Sertifikat"}
              required
              placeholder="Tuliskan nama sertifikat"
              value={selectedCertificate?.name}
              setValue={(val) => handleChangeCertificate("name", val)}
            />
            {selectedCertificate?.file ? (
              <div
                className={Styles.imagePreview}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <p>
                  Template Sertifikat<span>*</span>
                </p>
                <div className={Styles.imageWrapper}>
                  <img
                    src={
                      typeof selectedCertificate?.file === "string"
                        ? fileBaseUrl + selectedCertificate?.file
                        : URL.createObjectURL(selectedCertificate?.file)
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) =>
                      handleChangeCertificate("file", e.target.files[0])
                    }
                    hidden
                    ref={inputFileRef}
                    accept="image/jpeg"
                  />
                  <div
                    className={Styles.imageOverlay}
                    is-hovered={String(hovered)}
                  >
                    <Icon
                      icon={"edit-2"}
                      size={20}
                      color={"#C2C2C2"}
                      onClick={() => inputFileRef.current.click()}
                    />
                    <Icon
                      icon={"trash1"}
                      size={20}
                      color={"#C2C2C2"}
                      onClick={() => handleChangeCertificate("file", null)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <InputField
                title={"Template Sertifikat"}
                required
                isFile
                placeholder="Pilih template sertifikat Hanya mendukung file .jpeg"
                value={selectedCertificate?.file}
                setValue={(val) => handleChangeCertificate("file", val)}
                isImageOnly
              />
            )}
          </div>
          <div className={Styles.modalAction}>
            <Button
              text={"Kembali"}
              variant="outlined"
              onClick={() => setSelectedCertificate(null)}
            />
            <Button
              text={"Simpan"}
              disabled={
                !selectedCertificate?.file || !selectedCertificate?.name
              }
              onClick={() => handleSubmitCertificate()}
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={modalConfirmDelete}>
        <div className={Styles.modalConfirm}>
          <p className={Styles.headText}>Hapus Template Sertifikat</p>
          <img src={LogoBlendQ} alt="" />
          <p className={Styles.descText}>
            Apakah kamu yakin ingin menghapus template sertifikat ini?
          </p>
          <div className={Styles.modalAction}>
            <Button
              text={"Kembali"}
              variant="outlined"
              onClick={() => setModalConfirmDelete(false)}
            />
            <Button
              text={"Hapus"}
              onClick={() => handleDeleteCertificate(modalConfirmDelete._id)}
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={successModal}>
        <FileUploadSuccess
          headText="Template Sertifikat Berhasil Disimpan"
          text={
            "Berhasil menambahkan template sertifikat baru kedalam daftar template sertifikat."
          }
          status="success"
          onClose={() => setSuccessModal(false)}
        />
      </Modal>
      <Modal isOpen={errorDelete.length > 0}>
        <ModalError
          title={"Sertifikat Tidak Dapat Dihapus"}
          content={
            <div className={Styles.errorModalContainer}>
              <p>
                Sertifikat {modalConfirmDelete.name} ini tidak dapat dihapus
                karena template ini sedang digunakan di kelas berikut:
              </p>
              <ul>
                {errorDelete.map((el, idx) => (
                  <li key={idx}>{el.title}</li>
                ))}
              </ul>
            </div>
          }
          onClose={() => {
            setModalConfirmDelete(false);
            setErrorDelete([]);
          }}
        />
      </Modal>
      <Footer />
    </div>
  );
}
